<ng-container *ngIf="asyncAfterContentInit" class="core-form-content-component">
  <div [formGroup]="form" class="core-form-content-component-form">
    <div>
      <div fxFlex="" fxLayout="column" class="core-form-content-component-wrapper">
        <ng-container [ngSwitch]="forcedFieldType || field.form.formFieldType">
          <ng-container *ngSwitchCase="'text'">
            <div class="form-input-size field-container" [ngClass]="field?.form?.cssClass">
              <mat-label class="bold d-flex clearfix pt-2 pb-2">
                <span>
                  {{
                    field.form.apiName
                      ? field.form.name
                      : (cid + '.' + (field.form.name ? field.form.name : field.name) | translate)
                  }}
                </span>
                <span *ngIf="checkRequiredFields(field.name)" class="mat-error required-asterisk inline"> * </span>
              </mat-label>
              <mat-form-field class="full-width">
                <div matPrefix *ngIf="field.form.prefix">
                  <ng-container *ngFor="let prefix of field.form.prefix">
                    <ng-container [ngSwitch]="prefix.list.displayType">
                      <ng-container *ngSwitchCase="null">
                        {{ prefix.list.translate ? (prefix.list.staticText | translate) : prefix.list.staticText }}
                      </ng-container>
                    </ng-container>
                  </ng-container>
                </div>

                <input
                  type="text"
                  matInput
                  [formControlName]="field.name"
                  [attr.testId]="field.name"
                  [placeholder]="
                    field.form.apiName
                      ? field.form.name
                      : (cid + '.' + (field.form.placeHolder ? field.form.placeHolder : field.name) | translate)
                  "
                  [afaqyHeatmap]="{ page: service?.cid, target: 'form-input-' + field.name }"
                />

                <div matSuffix *ngIf="field.form.suffix">
                  <ng-container *ngFor="let suffix of field.form.suffix">
                    <ng-container [ngSwitch]="suffix.list.displayType">
                      <ng-container *ngSwitchCase="null">
                        {{ suffix.list.translate ? (suffix.list.staticText | translate) : suffix.list.staticText }}
                      </ng-container>
                    </ng-container>
                  </ng-container>
                </div>

                <mat-hint align="start" *ngIf="field?.form?.hint">
                  <ng-container [ngSwitch]="field?.form?.hint?.list?.displayType">
                    <ng-container *ngSwitchCase="null">
                      <strong>
                        {{
                          field?.form?.hint?.list?.translate
                            ? (field?.form?.hint?.list?.staticText | translate)
                            : field?.form?.hint?.list?.staticText
                        }}
                      </strong>
                    </ng-container>
                    <ng-container *ngSwitchCase="'odometerLastUpdateFn'">
                      <strong>
                        {{ field?.form?.hint?.list?.fn() }}
                      </strong>
                    </ng-container>
                  </ng-container>
                </mat-hint>

                <mat-error>
                  <ng-container *ngTemplateOutlet="validationErrors"></ng-container>
                </mat-error>
              </mat-form-field>
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="'number'">
            <div class="form-input-size field-container" [ngClass]="field?.form?.cssClass">
              <mat-label class="bold d-flex clearfix pt-2 pb-2">
                <span>{{ cid + '.' + (field.form.name ? field.form.name : field.name) | translate }}</span>
                <span *ngIf="checkRequiredFields(field.name)" class="mat-error required-asterisk inline"> * </span>
              </mat-label>
              <mat-form-field
                class="full-width"
                [floatLabel]="customCid === 'work-order' || customCid === 'service-entry' ? 'always' : 'auto'"
              >
                <div matPrefix *ngIf="field.form.prefix">
                  <ng-container *ngFor="let prefix of field.form.prefix">
                    <ng-container [ngSwitch]="prefix.list.displayType">
                      <ng-container *ngSwitchCase="null">
                        {{ prefix.list.translate ? (prefix.list.staticText | translate) : prefix.list.staticText }}
                      </ng-container>
                    </ng-container>
                  </ng-container>
                </div>

                <input
                  type="number"
                  onkeydown="return event.keyCode !== 69"
                  matInput
                  [formControlName]="field.name"
                  [min]="
                    field?.form?.minValue?.prop
                      ? field?.form?.minValue?.prop
                      : field?.form?.minValue?.fn
                      ? field?.form?.minValue?.fn()
                      : field?.form?.minValue?.value || field?.form?.minValue?.value === 0
                      ? field?.form?.minValue?.value
                      : null
                  "
                  [max]="
                    field?.form?.maxValue?.prop
                      ? field?.form?.maxValue?.prop
                      : field?.form?.maxValue?.fn
                      ? field?.form?.maxValue?.fn()
                      : field?.form?.maxValue?.value || field?.form?.maxValue?.value === 0
                      ? field?.form?.maxValue?.value
                      : null
                  "
                  [afaqyHeatmap]="{ page: service?.cid, target: 'form-input-' + field.name }"
                  [attr.testId]="field.name"
                  [placeholder]="cid + '.' + (field.form.placeHolder ? field.form.placeHolder : field.name) | translate"
                />

                <div matSuffix *ngIf="field.form.suffix">
                  <ng-container *ngFor="let suffix of field.form.suffix">
                    <ng-container [ngSwitch]="suffix.list.displayType">
                      <ng-container *ngSwitchCase="null">
                        {{ suffix.list.translate ? (suffix.list.staticText | translate) : suffix.list.staticText }}
                      </ng-container>
                    </ng-container>
                  </ng-container>
                </div>

                <mat-hint align="start" *ngIf="field?.form?.hint">
                  <ng-container [ngSwitch]="field?.form?.hint?.list?.displayType">
                    <ng-container *ngSwitchCase="null">
                      <strong>
                        {{
                          field?.form?.hint?.list?.translate
                            ? (field?.form?.hint?.list?.staticText | translate)
                            : field?.form?.hint?.list?.staticText
                        }}
                      </strong>
                    </ng-container>
                    <ng-container *ngSwitchCase="'odometerLastUpdateFn'">
                      <strong>
                        {{ field?.form?.hint?.list?.fn() }}
                      </strong>
                    </ng-container>
                  </ng-container>
                </mat-hint>

                <mat-error>
                  <ng-container *ngTemplateOutlet="validationErrors"></ng-container>
                </mat-error>
              </mat-form-field>
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="'number_RestrictInputRange'">
            <div class="form-input-size field-container" [ngClass]="field?.form?.cssClass">
              <mat-form-field class="full-width">
                <mat-label class="bold d-flex clearfix pt-2 pb-2">
                  <span>{{ cid + '.' + (field.form.name ? field.form.name : field.name) | translate }}</span>
                  <span *ngIf="checkRequiredFields(field.name)" class="mat-error required-asterisk inline"> * </span>
                </mat-label>
                <div matPrefix *ngIf="field.form.prefix">
                  <ng-container *ngFor="let prefix of field.form.prefix">
                    <ng-container [ngSwitch]="prefix.list.displayType">
                      <ng-container *ngSwitchCase="null">
                        {{ prefix.list.translate ? (prefix.list.staticText | translate) : prefix.list.staticText }}
                      </ng-container>
                    </ng-container>
                  </ng-container>
                </div>

                <input
                  type="number"
                  matInput
                  [formControlName]="field.name"
                  [min]="
                    field?.form?.minValue?.prop
                      ? field?.form?.minValue?.prop
                      : field?.form?.minValue?.fn
                      ? field?.form?.minValue?.fn()
                      : field?.form?.minValue?.value || field?.form?.minValue?.value === 0
                      ? field?.form?.minValue?.value
                      : null
                  "
                  [max]="
                    field?.form?.maxValue?.prop
                      ? field?.form?.maxValue?.prop
                      : field?.form?.maxValue?.fn
                      ? field?.form?.maxValue?.fn()
                      : field?.form?.maxValue?.value || field?.form?.maxValue?.value === 0
                      ? field?.form?.maxValue?.value
                      : null
                  "
                  appNumericMinMax
                  [numericInput]="true"
                  [numericControl]="form.controls[field.name]"
                  [attr.testId]="field.name"
                  [afaqyHeatmap]="{ page: service?.cid, target: 'form-input-' + field.name }"
                  [placeholder]="cid + '.' + (field.form.placeHolder ? field.form.placeHolder : field.name) | translate"
                />

                <div matSuffix *ngIf="field.form.suffix">
                  <ng-container *ngFor="let suffix of field.form.suffix">
                    <ng-container [ngSwitch]="suffix.list.displayType">
                      <ng-container *ngSwitchCase="null">
                        {{ suffix.list.translate ? (suffix.list.staticText | translate) : suffix.list.staticText }}
                      </ng-container>
                    </ng-container>
                  </ng-container>
                </div>

                <mat-hint align="start" *ngIf="field?.form?.hint">
                  <ng-container [ngSwitch]="field?.form?.hint?.list?.displayType">
                    <ng-container *ngSwitchCase="null">
                      <strong>
                        {{
                          field?.form?.hint?.list?.translate
                            ? (field?.form?.hint?.list?.staticText | translate)
                            : field?.form?.hint?.list?.staticText
                        }}
                      </strong>
                    </ng-container>
                    <ng-container *ngSwitchCase="'odometerLastUpdateFn'">
                      <strong>
                        {{ field?.form?.hint?.list?.fn() }}
                      </strong>
                    </ng-container>
                  </ng-container>
                </mat-hint>

                <mat-error>
                  <ng-container *ngTemplateOutlet="validationErrors"></ng-container>
                </mat-error>
              </mat-form-field>
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="'textarea'">
            <div class="form-input-size field-container" [ngClass]="field?.form?.cssClass">
              <mat-form-field class="full-width">
                <mat-label class="bold d-flex clearfix pt-2 pb-2">
                  <span>{{ cid + '.' + (field.form.name ? field.form.name : field.name) | translate }}</span>
                  <span *ngIf="checkRequiredFields(field.name)" class="mat-error required-asterisk inline"> * </span>
                </mat-label>
                <textarea
                  matInput
                  [formControlName]="field.name"
                  [attr.testId]="field.name"
                  [placeholder]="cid + '.' + (field.form.placeHolder ? field.form.placeHolder : field.name) | translate"
                  [afaqyHeatmap]="{ page: service?.cid, target: 'form-input-' + field.name }"
                ></textarea>
                <mat-error>
                  <ng-container *ngTemplateOutlet="validationErrors"></ng-container>
                </mat-error>
              </mat-form-field>
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="'colorPicker'">
            <div class="form-input-size field-container color-picker-style1">
              <mat-label class="bold d-flex clearfix pt-2 pb-2">
                <span>{{ cid + '.' + (field.form.name ? field.form.name : field.name) | translate }}</span>
                <span *ngIf="checkRequiredFields(field.name)" class="mat-error required-asterisk inline"> * </span>
              </mat-label>
              <!-- <mat-form-field [floatLabel]='false' class='full-width'>
                <button
                  class='color-square'
                  tabindex='-1'
                  [style.background]="form.get(field.name).value ? form.get(field.name).value : '#fff'"
                  matPrefix
                  (click)='focusInputById(field.name)'
                ></button>
                <input
                  matInput
                  [id]='field.name'
                  autocomplete='off'
                  [formControlName]='field.name'
                  [cpWidth]="'160px'"
                  [cpPresetColors]="['#1862DB', '#FF9900', '#2EB8FF', '#FF0000', '#E5237F', '#13E9CC', '#7FFF00']"
                  [colorPicker]='form.get(field.name).value'
                  [value]='form.get(field.name).value'
                  [cpOutputFormat]="'hex'"
                  (colorPickerChange)='form.get(field.name).setValue($event); markAsDirty(field.name)'
                  [cpPosition]="
                    field?.form?.colorPickerOptions?.position ? field.form.colorPickerOptions.position : 'bottom'
                  "
                  [cpPositionRelativeToArrow]='false'
                  [attr.testId]='field.name'
                  [placeholder]='cid + "." + (field.form.placeHolder ? field.form.placeHolder : field.name) | translate'
                />

                <mat-error>
                  <ng-container *ngTemplateOutlet='validationErrors'></ng-container>
                </mat-error>
              </mat-form-field> -->
              <app-material-color-picker
                [service]="service"
                [cid]="cid"
                [form]="form"
                [field]="field"
              ></app-material-color-picker>
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="'password'">
            <div fxFlex fxLayout="column" class="form-input-size field-container">
              <div fxFlex fxLayout>
                <mat-form-field class="full-width">
                  <mat-label class="bold d-flex clearfix pt-2 pb-2">
                    <span>{{ cid + '.' + (field.form.name ? field.form.name : field.name) | translate }}</span>
                    <span *ngIf="checkRequiredFields(field.name)" class="mat-error required-asterisk inline"> *</span>
                  </mat-label>
                  <input type="password" matInput [formControlName]="field.name" [attr.testId]="field.name" />

                  <mat-error>
                    <ng-container *ngTemplateOutlet="validationErrors"></ng-container>
                  </mat-error>
                </mat-form-field>
                <button
                  mat-icon-button
                  color="primary"
                  *ngIf="form.controls[field.name]?.disabled && isEdit && field?.form?.editDisabled"
                  class="edit"
                  (click)="enableControl(form.controls[field.name])"
                  [afaqyHeatmap]="{ page: service?.cid, target: 'form-input-' + field.name }"
                >
                  <mat-icon class="mat-18">edit</mat-icon>
                </button>
              </div>
            </div>
            <div fxFlex fxLayout [@showHide] *ngIf="!form.controls[field.name]?.disabled">
              <div class="password-guide" *ngIf="field?.hints?.password_helper">
                <ul>
                  <li
                    *ngIf="field?.hints?.minLength"
                    [ngClass]="{
                      success: !form.get(field?.name)?.errors?.minlength && !form.get(field?.name)?.errors?.required,
                      danger:
                        form.get(field?.name)?.errors?.minlength ||
                        (form.get(field?.name)?.errors?.required && form.get(field?.name)?.touched)
                    }"
                  >
                    <mat-icon
                      *ngIf="!form.get(field?.name)?.errors?.minlength && !form.get(field?.name)?.errors?.required"
                    >
                      check
                    </mat-icon>
                    <mat-icon
                      *ngIf="
                        form.get(field?.name)?.errors?.minlength ||
                        (form.get(field?.name)?.errors?.required && form.get(field?.name)?.touched)
                      "
                    >
                      close
                    </mat-icon>
                    <!--                                <div-->
                    <!--                                  *ngIf="!form.get(field?.name)?.touched || (!form.get(field?.name)?.dirty && form.get(field?.name)?.touched)"-->
                    <!--                                  class="square-icon"></div>-->
                    <span>{{ field?.hints?.minLength }} {{ 'validation_errors.characters' | translate }}</span>
                  </li>
                  <li
                    *ngIf="field?.hints?.hasSpecialCharacters"
                    [ngClass]="{
                      success:
                        !form.get(field?.name)?.errors?.hasSpecialCharacters &&
                        !form.get(field?.name)?.errors?.required,
                      danger:
                        form.get(field?.name)?.errors?.hasSpecialCharacters ||
                        (form.get(field?.name)?.errors?.required && form.get(field?.name)?.touched)
                    }"
                  >
                    <mat-icon
                      *ngIf="
                        !form.get(field?.name)?.errors?.hasSpecialCharacters && !form.get(field?.name)?.errors?.required
                      "
                    >
                      check
                    </mat-icon>
                    <mat-icon
                      *ngIf="
                        form.get(field?.name)?.errors?.hasSpecialCharacters ||
                        (form.get(field?.name)?.errors?.required && form.get(field?.name)?.touched)
                      "
                    >
                      close
                    </mat-icon>
                    <!--                                <div-->
                    <!--                                  *ngIf="!form.get(field?.name)?.touched || (!form.get(field?.name)?.dirty && form.get(field?.name)?.touched)"-->
                    <!--                                  class="square-icon"></div>-->
                    <span>{{ 'validation_errors.Special Character' | translate }}</span>
                    <span
                      *ngIf="
                        form.get(field?.name)?.errors?.hasSpecialCharacters ||
                        (form.get(field?.name)?.errors?.required && form.get(field?.name)?.touched)
                      "
                    >
                      {{ 'common.ex:' | translate }} {{ field?.hints?.hasSpecialCharacters }}
                    </span>
                  </li>
                  <li
                    *ngIf="field?.hints?.hasCapitalCase && field?.hints?.hasSmallCase && field?.hints?.hasNumber"
                    [ngClass]="{
                      success:
                        !form.get(field?.name)?.errors?.hasCapitalCase &&
                        !form.get(field?.name)?.errors?.hasNumber &&
                        !form.get(field?.name)?.errors?.hasSmallCase &&
                        !form.get(field?.name)?.errors?.required,
                      danger:
                        form.get(field?.name)?.errors?.hasCapitalCase ||
                        form.get(field?.name)?.errors?.hasNumber ||
                        form.get(field?.name)?.errors?.hasSmallCase ||
                        (form.get(field?.name)?.errors?.required && form.get(field?.name)?.touched)
                    }"
                  >
                    <mat-icon
                      *ngIf="
                        !form.get(field?.name)?.errors?.hasCapitalCase &&
                        !form.get(field?.name)?.errors?.hasNumber &&
                        !form.get(field?.name)?.errors?.hasSmallCase &&
                        !form.get(field?.name)?.errors?.required
                      "
                    >
                      check
                    </mat-icon>
                    <mat-icon
                      *ngIf="
                        form.get(field?.name)?.errors?.hasCapitalCase ||
                        form.get(field?.name)?.errors?.hasNumber ||
                        form.get(field?.name)?.errors?.hasSmallCase ||
                        (form.get(field?.name)?.errors?.required && form.get(field?.name)?.touched)
                      "
                    >
                      close
                    </mat-icon>
                    <!--                                <div-->
                    <!--                                  *ngIf="!form.get(field?.name)?.touched || (!form.get(field?.name)?.dirty && form.get(field?.name)?.touched)"-->
                    <!--                                  class="square-icon"></div>-->
                    <span>{{ 'validation_errors.Small letter & Capital letter & Number' | translate }}</span>
                  </li>
                </ul>
              </div>
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="'confirmPassword'">
            <div
              fxFlex
              fxLayout="column"
              class="form-input-size field-container"
              [@showHide]
              *ngIf="form.controls['password'].enabled"
            >
              <div fxFlex fxLayout>
                <mat-form-field class="full-width">
                  <mat-label class="bold d-flex clearfix pt-2 pb-2">
                    <span>{{ cid + '.' + (field.form.name ? field.form.name : field.name) | translate }}</span>
                    <span class="mat-error required-asterisk inline"> *</span>
                  </mat-label>
                  <input
                    type="password"
                    matInput
                    [formControlName]="field.name"
                    [attr.testId]="field.name"
                    [afaqyHeatmap]="{ page: service?.cid, target: 'form-input-' + field.name }"
                  />

                  <mat-error>
                    <ng-container *ngTemplateOutlet="validationErrors"></ng-container>
                  </mat-error>
                </mat-form-field>
              </div>
              <!-- confirm password is always required -->
            </div>

            <div fxFlex fxLayout [@showHide] *ngIf="!form.controls['password']?.disabled">
              <div
                class="password-guide"
                *ngIf="
                  field?.hints?.password_helper && (form.controls['password'].value || form.controls[field?.name].value)
                "
              >
                <ul>
                  <li
                    *ngIf="field?.hints?.password_helper"
                    [ngClass]="{
                      success: !form.get(field?.name)?.errors?.passwordsDontMatch,
                      danger: form.get(field?.name)?.errors?.passwordsDontMatch
                    }"
                  >
                    <mat-icon *ngIf="!form.get(field?.name)?.errors?.passwordsDontMatch"> check</mat-icon>
                    <mat-icon *ngIf="form.get(field?.name)?.errors?.passwordsDontMatch"> close</mat-icon>
                    <!--                                <div-->
                    <!--                                  *ngIf="!form.get(field?.name)?.touched || (!form.get(field?.name)?.dirty && form.get(field?.name)?.touched)"-->
                    <!--                                  class="square-icon"></div>-->
                    <span>
                      {{ 'validation_errors.passwordsMatch' | translate }}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="'email'">
            <div class="form-input-size field-container">
              <mat-form-field class="full-width">
                <mat-label class="bold d-flex clearfix pt-2 pb-2">
                  <span>{{ cid + '.' + (field.form.name ? field.form.name : field.name) | translate }}</span>
                  <span *ngIf="checkRequiredFields(field.name)" class="mat-error required-asterisk inline"> *</span>
                </mat-label>
                <input
                  type="email"
                  matInput
                  [formControlName]="field.name"
                  [attr.testId]="field.name"
                  [placeholder]="cid + '.' + (field.form.placeHolder ? field.form.placeHolder : field.name) | translate"
                  [afaqyHeatmap]="{ page: service?.cid, target: 'form-input-' + field.name }"
                />

                <mat-error>
                  <ng-container *ngTemplateOutlet="validationErrors"></ng-container>
                </mat-error>
              </mat-form-field>
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="'multi_select'">
            <div class="form-input-size field-container">
              <mat-form-field class="full-width">
                <mat-label class="bold d-flex clearfix pt-2 pb-2">
                  <span>{{ cid + '.' + (field.form.name ? field.form.name : field.name) | translate }}</span>
                  <span *ngIf="checkRequiredFields(field.name)" class="mat-error required-asterisk inline"> *</span>
                </mat-label>
                <mat-select [formControlName]="field.name" multiple [attr.testId]="field.name">
                  <mat-option *ngFor="let item of service.lists[field.form.listPrefix]" [value]="item.id">
                    {{ item.name || item.displayName | translate }}
                  </mat-option>
                </mat-select>

                <mat-error>
                  <ng-container *ngTemplateOutlet="validationErrors"></ng-container>
                </mat-error>
              </mat-form-field>
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="'ng_select_multiple'">
            <div
              class="form-input-size field-container ng_select_container"
              [ngClass]="field?.form?.cssClass"
              [class.error]="
                (form.get(field?.name)?.touched || isSubmitted) &&
                form.get(field?.name)?.invalid &&
                form.get(field?.name)?.errors
              "
            >
              <label class="bold d-flex clearfix pt-2 pb-2">
                <span>
                  {{
                    field.form.apiName
                      ? field.form.name
                      : (cid + '.' + (field.form.name ? field.form.name : field.name) | translate)
                  }}
                </span>
                <span *ngIf="checkRequiredFields(field.name)" class="mat-error required-asterisk inline"> * </span>
              </label>
              <ng-select
                [appearance]="'outline'"
                class="ng-select-style1"
                [ngClass]="{ 'required-asterisk-inline-before': checkRequiredFields(field.name) }"
                [items]="service.lists[field.form.listPrefix]"
                [multiple]="true"
                bindLabel="name"
                bindValue="id"
                dropdownPosition="auto"
                [closeOnSelect]="false"
                [formControlName]="field.name"
                appendTo="body"
                [attr.testId]="field.name"
                [afaqyHeatmap]="{ page: service?.cid, target: 'form-input-' + field.name }"
              >
                <ng-template ng-header-tmp>
                  <div class="d-flex py-3">
                    <button
                      class="mr-2"
                      (click)="ngSelect_selectAll(form.controls[field.name], field.form.listPrefix, true)"
                      mat-stroked-button
                      color="primary"
                      [afaqyHeatmap]="{
                        page: service?.cid,
                        target: 'form-input-ng-select_multiple-selectAll' + field.name
                      }"
                    >
                      {{ 'common.selectAll' | translate }}
                    </button>
                    <button
                      (click)="ngSelect_selectAll(form.controls[field.name], field.form.listPrefix, false)"
                      mat-stroked-button
                      color="warn"
                      [afaqyHeatmap]="{
                        page: service?.cid,
                        target: 'form-input-ng-select_multiple-UnselectAll' + field.name
                      }"
                    >
                      {{ 'common.unselectAll' | translate }}
                    </button>
                  </div>
                </ng-template>

                <ng-template ng-label-tmp let-item="item" let-clear="clear">
                  <div class="d-inline-flex">
                    <ng-container *ngIf="field?.form?.ngSelectOptions?.prefix">
                      <ng-container *ngFor="let prefix of field?.form?.ngSelectOptions?.prefix">
                        <span class="ng_select_prefix" *ngIf="prefix?.prop">
                          <ng-container [ngSwitch]="prefix?.displayType">
                            <ng-container *ngSwitchCase="null">
                              <span class="cell-display-type text{{ prefix?.cssClass }}" *ngIf="item[prefix.prop]">
                                {{ item[prefix.prop] }}
                              </span>
                            </ng-container>
                            <ng-container *ngSwitchCase="'color'">
                              <span
                                class="cell-display-type color {{ prefix?.cssClass }}"
                                *ngIf="item[prefix.prop]"
                                [style.background-color]="item[prefix.prop]"
                              ></span>
                            </ng-container>
                            <ng-container *ngSwitchCase="'img'">
                              <span class="cell-display-type img {{ prefix?.cssClass }}">
                                <img class="img-data" *ngIf="item[prefix.prop]" [src]="item[prefix.prop]" alt="" />
                                <img
                                  class="img-placeholder"
                                  *ngIf="!item[prefix.prop]"
                                  src="{{ prefix.placeHolder ? prefix.placeHolder : 'assets/image-placeholder.png' }}"
                                  alt=""
                                />
                              </span>
                            </ng-container>
                          </ng-container>
                        </span>
                      </ng-container>
                    </ng-container>
                  </div>

                  <span class="ng-value-label d-inline-flex">{{ item.name || item.displayName | translate }}</span>
                  <span class="ng-value-icon right ml-2" (click)="clear(item)">
                    <mat-icon [svgIcon]="'close-circle'" class="mdi-icon" [attr.size]="'14px'"></mat-icon>
                  </span>
                </ng-template>

                <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index" let-search="searchTerm">
                  <div class="d-flex align-items-center multiselect-option-container">
                    <mat-checkbox
                      class="d-inline-flex mr-3 dropdown-checkbox"
                      id="{{ item }}-option-{{ index }}"
                      color="primary"
                      [checked]="checkSelected(form.controls[field.name], item.id)"
                      (click)="preventDefault($event)"
                      [afaqyHeatmap]="{
                        page: service?.cid,
                        target: 'form-input-' + item.name
                      }"
                    ></mat-checkbox>
                    <div class="d-inline-flex">
                      <ng-container *ngIf="field?.form?.ngSelectOptions?.prefix">
                        <ng-container *ngFor="let prefix of field?.form?.ngSelectOptions?.prefix">
                          <span class="ng_select_prefix" *ngIf="prefix?.prop">
                            <ng-container [ngSwitch]="prefix?.displayType">
                              <ng-container *ngSwitchCase="null">
                                <span class="cell-display-type text{{ prefix?.cssClass }}" *ngIf="item[prefix.prop]">
                                  {{ item[prefix.prop] }}
                                </span>
                              </ng-container>
                              <ng-container *ngSwitchCase="'color'">
                                <span
                                  class="cell-display-type color {{ prefix?.cssClass }} dropdown-color"
                                  *ngIf="item[prefix.prop]"
                                  [style.background-color]="item[prefix.prop]"
                                ></span>
                              </ng-container>
                              <ng-container *ngSwitchCase="'img'">
                                <span class="cell-display-type img {{ prefix?.cssClass }}">
                                  <img class="img-data" *ngIf="item[prefix.prop]" [src]="item[prefix.prop]" alt="" />
                                  <img
                                    class="img-placeholder"
                                    *ngIf="!item[prefix.prop]"
                                    src="{{ prefix.placeHolder ? prefix.placeHolder : 'assets/image-placeholder.png' }}"
                                    alt=""
                                  />
                                </span>
                              </ng-container>
                            </ng-container>
                          </span>
                        </ng-container>
                      </ng-container>
                    </div>

                    <div class="d-inline-flex dropdown-span" [ngOptionHighlight]="search">
                      {{ item.name || item.displayName | translate }}
                    </div>
                  </div>
                </ng-template>
              </ng-select>

              <mat-error>
                <div class="mat-form-field-subscript-wrapper mat-error">
                  <ng-container *ngTemplateOutlet="validationErrors"></ng-container>
                </div>
              </mat-error>
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="'ng_added_select_multiple'">
            <div
              class="form-input-size field-container ng_select_container"
              [ngClass]="field?.form?.cssClass"
              [class.error]="
                (form.get(field?.name)?.touched || isSubmitted) &&
                form.get(field?.name)?.invalid &&
                form.get(field?.name)?.errors
              "
            >
              <ng-select
                class="ng-select-style1"
                [items]="service.lists[field.form.listPrefix]"
                [addTag]="addTagNameFn"
                [multiple]="true"
                [hideSelected]="true"
                [clearSearchOnAdd]="true"
                bindLabel="name"
                bindValue="name"
                dropdownPosition="auto"
                [closeOnSelect]="false"
                [formControlName]="field.name"
                appendTo="body"
                [attr.testId]="field.name"
                [afaqyHeatmap]="{ page: service?.cid, target: 'form-input-' + field.name }"
              >
                <ng-template ng-label-tmp let-item="item" let-clear="clear">
                  <div class="d-inline-flex">
                    <ng-container *ngIf="field?.form?.ngSelectOptions?.prefix">
                      <ng-container *ngFor="let prefix of field?.form?.ngSelectOptions?.prefix">
                        <span class="ng_select_prefix" *ngIf="prefix?.prop">
                          <ng-container [ngSwitch]="prefix?.displayType">
                            <ng-container *ngSwitchCase="null">
                              <span class="cell-display-type text{{ prefix?.cssClass }}" *ngIf="item[prefix.prop]">
                                {{ item[prefix.prop] }}
                              </span>
                            </ng-container>
                            <ng-container *ngSwitchCase="'color'">
                              <span
                                class="cell-display-type color {{ prefix?.cssClass }}"
                                *ngIf="item[prefix.prop]"
                                [style.background-color]="item[prefix.prop]"
                              ></span>
                            </ng-container>
                            <ng-container *ngSwitchCase="'img'">
                              <span class="cell-display-type img {{ prefix?.cssClass }}">
                                <img class="img-data" *ngIf="item[prefix.prop]" [src]="item[prefix.prop]" alt="" />
                                <img
                                  class="img-placeholder"
                                  *ngIf="!item[prefix.prop]"
                                  src="{{ prefix.placeHolder ? prefix.placeHolder : 'assets/image-placeholder.png' }}"
                                  alt=""
                                />
                              </span>
                            </ng-container>
                          </ng-container>
                        </span>
                      </ng-container>
                    </ng-container>
                  </div>

                  <span class="ng-value-label d-inline-flex">{{ item.name || item.displayName | translate }}</span>
                  <span class="ng-value-icon right ml-2" (click)="clear(item)">
                    <mat-icon [svgIcon]="'close-circle'" class="mdi-icon" [attr.size]="'14px'"></mat-icon>
                  </span>
                </ng-template>

                <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index" let-search="searchTerm">
                  <div class="d-flex align-items-center">
                    <!-- <mat-checkbox
                      class="d-inline-flex mr-3"
                      id="{{ item }}-option-{{ index }}"
                      color="primary"
                      [checked]="checkLabelSelected(form.controls[field.name], item.name)"
                      (click)="preventDefault($event)"
                    ></mat-checkbox> -->
                    <div class="d-inline-flex">
                      <ng-container *ngIf="field?.form?.ngSelectOptions?.prefix">
                        <ng-container *ngFor="let prefix of field?.form?.ngSelectOptions?.prefix">
                          <span class="ng_select_prefix" *ngIf="prefix?.prop">
                            <ng-container [ngSwitch]="prefix?.displayType">
                              <ng-container *ngSwitchCase="null">
                                <span class="cell-display-type text{{ prefix?.cssClass }}" *ngIf="item[prefix.prop]">
                                  {{ item[prefix.prop] }}
                                </span>
                              </ng-container>
                              <ng-container *ngSwitchCase="'color'">
                                <span
                                  class="cell-display-type color {{ prefix?.cssClass }}"
                                  *ngIf="item[prefix.prop]"
                                  [style.background-color]="item[prefix.prop]"
                                ></span>
                              </ng-container>
                              <ng-container *ngSwitchCase="'img'">
                                <span class="cell-display-type img {{ prefix?.cssClass }}">
                                  <img class="img-data" *ngIf="item[prefix.prop]" [src]="item[prefix.prop]" alt="" />
                                  <img
                                    class="img-placeholder"
                                    *ngIf="!item[prefix.prop]"
                                    src="{{ prefix.placeHolder ? prefix.placeHolder : 'assets/image-placeholder.png' }}"
                                    alt=""
                                  />
                                </span>
                              </ng-container>
                            </ng-container>
                          </span>
                        </ng-container>
                      </ng-container>
                    </div>

                    <div class="d-inline-flex" [ngOptionHighlight]="search">
                      {{ item.name || item.displayName }}
                    </div>
                  </div>
                </ng-template>
              </ng-select>

              <mat-error>
                <div class="mat-form-field-subscript-wrapper mat-error">
                  <ng-container *ngTemplateOutlet="validationErrors"></ng-container>
                </div>
              </mat-error>
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="'ng_select_active'">
            <div
              class="form-input-size field-container ng_select_container"
              [ngClass]="field?.form?.cssClass"
              [class.error]="
                (form.get(field?.name)?.touched || isSubmitted) &&
                form.get(field?.name)?.invalid &&
                form.get(field?.name)?.errors
              "
            >
              <app-ng-select-active
                [requiredAsterisk]="checkRequiredFields(field.name)"
                [item]="form?.get(field?.name)?.value"
                [service]="service"
                [form]="form"
                [isEdit]="isEdit"
                [field]="field"
                [isClone]="isClone"
              >
              </app-ng-select-active>

              <mat-error>
                <div class="mat-form-field-subscript-wrapper mat-error">
                  <ng-container *ngTemplateOutlet="validationErrors"></ng-container>
                </div>
              </mat-error>
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="'ng_select'">
            <div
              class="form-input-size field-container"
              [ngClass]="field?.form?.cssClass"
              [class.error]="
                (form.get(field?.name)?.touched || isSubmitted) &&
                form.get(field?.name)?.invalid &&
                form.get(field?.name)?.errors
              "
              [afaqyHeatmap]="{ page: service?.cid, target: 'form-input-' + field.name }"
            >
              <label class="bold d-flex clearfix pt-2 pb-2">
                <span>
                  {{
                    field.form.apiName
                      ? field.form.name
                      : (cid + '.' + (field.form.name ? field.form.name : field.name) | translate)
                  }}
                </span>
                <span *ngIf="checkRequiredFields(field.name)" class="mat-error required-asterisk inline"> * </span>
              </label>
              <ng-select
                [appearance]="'outline'"
                class="ng-select-style1"
                [ngClass]="{ 'required-asterisk-inline-before': checkRequiredFields(field.name) }"
                [items]="service.lists[field.form.listPrefix]"
                [multiple]="false"
                bindLabel="name"
                [bindValue]="field?.form?.bindValue ? field?.form?.bindValue : 'id'"
                dropdownPosition="auto"
                [closeOnSelect]="true"
                [formControlName]="field.name"
                (change)="onChangeValue(field, $event)"
                appendTo="body"
                [attr.testId]="field.name"
                [afaqyHeatmap]="{ page: service?.cid, target: 'form-input-' + field.name }"
              >
                <ng-template ng-label-tmp let-item="item" let-clear="clear">
                  <div class="d-inline-flex">
                    <ng-container *ngIf="field?.form?.ngSelectOptions?.prefix">
                      <ng-container *ngFor="let prefix of field?.form?.ngSelectOptions?.prefix">
                        <span class="ng_select_prefix" *ngIf="prefix?.prop">
                          <ng-container [ngSwitch]="prefix?.displayType">
                            <ng-container *ngSwitchCase="null">
                              <span class="cell-display-type text{{ prefix?.cssClass }}" *ngIf="item[prefix.prop]">
                                {{ item[prefix.prop] }}
                              </span>
                            </ng-container>
                            <ng-container *ngSwitchCase="'color'">
                              <span
                                class="cell-display-type color {{ prefix?.cssClass }}"
                                *ngIf="item[prefix.prop]"
                                [style.background-color]="item[prefix.prop]"
                              ></span>
                            </ng-container>
                            <ng-container *ngSwitchCase="'img'">
                              <span class="cell-display-type img {{ prefix?.cssClass }}">
                                <img class="img-data" *ngIf="item[prefix.prop]" [src]="item[prefix.prop]" alt="" />
                                <img
                                  class="img-placeholder"
                                  *ngIf="!item[prefix.prop]"
                                  src="{{ prefix.placeHolder ? prefix.placeHolder : 'assets/image-placeholder.png' }}"
                                  alt=""
                                />
                              </span>
                            </ng-container>
                          </ng-container>
                        </span>
                      </ng-container>
                    </ng-container>
                    <div [ngSwitch]="field?.form?.ngSelectOptions?.displayType">
                      <ng-container *ngSwitchCase="null">
                        <span class="ng-value-label d-inline-flex">
                          {{ item.name || item.displayName | translate }}
                        </span>
                        <span class="ng-value-icon right ml-2" (click)="clear(item)">
                          <mat-icon [svgIcon]="'close-circle'" class="mdi-icon" [attr.size]="'14px'"></mat-icon>
                        </span>
                      </ng-container>
                      <ng-container *ngSwitchCase="'img'">
                        <img
                          class="img-small-size"
                          *ngIf="field?.form?.ngSelectOptions?.displayType === 'img'"
                          src="{{ item['img'] ? item['img'] : 'assets/image-placeholder.png' }}"
                          alt=""
                        />
                      </ng-container>
                    </div>
                  </div>
                </ng-template>

                <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index" let-search="searchTerm">
                  <div class="d-flex align-items-center">
                    <div class="d-inline-flex">
                      <ng-container *ngIf="field?.form?.ngSelectOptions?.prefix">
                        <ng-container *ngFor="let prefix of field?.form?.ngSelectOptions?.prefix">
                          <span class="ng_select_prefix" *ngIf="prefix?.prop">
                            <ng-container [ngSwitch]="prefix?.displayType">
                              <ng-container *ngSwitchCase="null">
                                <span class="cell-display-type text{{ prefix?.cssClass }}" *ngIf="item[prefix.prop]">
                                  {{ item[prefix.prop] }}
                                </span>
                              </ng-container>
                              <ng-container *ngSwitchCase="'color'">
                                <span
                                  class="cell-display-type color {{ prefix?.cssClass }}"
                                  *ngIf="item[prefix.prop]"
                                  [style.background-color]="item[prefix.prop]"
                                ></span>
                              </ng-container>
                              <ng-container *ngSwitchCase="'img'">
                                <span class="cell-display-type img {{ prefix?.cssClass }}">
                                  <img class="img-data" *ngIf="item[prefix.prop]" [src]="item[prefix.prop]" alt="" />
                                  <img
                                    class="img-placeholder"
                                    *ngIf="!item[prefix.prop]"
                                    src="{{ prefix.placeHolder ? prefix.placeHolder : 'assets/image-placeholder.png' }}"
                                    alt=""
                                  />
                                </span>
                              </ng-container>
                            </ng-container>
                          </span>
                        </ng-container>
                      </ng-container>
                    </div>

                    <div class="d-inline-flex" [ngOptionHighlight]="search">
                      <div [ngSwitch]="field?.form?.ngSelectOptions?.displayType">
                        <ng-container *ngSwitchCase="null">
                          {{ item?.name || item?.displayName | translate }}
                        </ng-container>
                        <ng-container *ngSwitchCase="'img'">
                          <img
                            class="select-img-only"
                            *ngIf="item['img']"
                            src="{{ item['img'] ? item['img'] : 'assets/image-placeholder.png' }}"
                            alt=""
                          />
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </ng-select>

              <mat-error>
                <div class="mat-form-field-subscript-wrapper mat-error">
                  <ng-container *ngTemplateOutlet="validationErrors"></ng-container>
                </div>
              </mat-error>
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="'ng_select_api'">
            <div
              class="form-input-size field-container ng_select_container"
              [ngClass]="field?.form?.cssClass"
              [class.error]="
                (form.get(field?.name)?.touched || isSubmitted) &&
                form.get(field?.name)?.invalid &&
                form.get(field?.name)?.errors
              "
            >
              <app-ng-select-api
                [requiredAsterisk]="checkRequiredFields(field.name)"
                [item]="form?.get(field?.name)?.value"
                [service]="service"
                [form]="form"
                [isEdit]="isEdit"
                [field]="field"
                [isClone]="isClone"
                [formControlName]="field?.name"
                [name]="field?.name"
                [disabledInput]="
                  (field?.form?.editDisabled && isEdit) ||
                  field?.form?.disabled ||
                  (form?.controls)[field?.name]?.disabled
                "
                (valueChanged)="onChangeValue($event?.field, $event?.event)"
              >
              </app-ng-select-api>

              <mat-error>
                <div class="mat-form-field-subscript-wrapper mat-error">
                  <ng-container *ngTemplateOutlet="validationErrors"></ng-container>
                </div>
              </mat-error>
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="'checkbox'">
            <div class="form-input-size field-container d-flex" [ngClass]="field?.form?.cssClass">
              <mat-checkbox
                color="primary"
                class="bold d-flex clearfix pt-2 pb-2"
                [formControlName]="field.name"
                [attr.testId]="field.name"
                [afaqyHeatmap]="{ page: service?.cid, target: 'form-input-' + field.name }"
              >
                {{ cid + '.' + field.name | translate }}
              </mat-checkbox>

              <div matSuffix *ngIf="field.form.suffix" class="display-inline-flex">
                <ng-container *ngFor="let suffix of field.form.suffix">
                  <ng-container [ngSwitch]="suffix.form.displayType">
                    <ng-container *ngSwitchCase="'icon'">
                      {{ suffix.form.translate ? (suffix.form.staticText | translate) : suffix.form.staticText }}
                      <span class="cell-display-type icon">
                        <ng-container *ngIf="suffix.form.icon">
                          <ng-container [ngSwitch]="suffix.form.icon.type">
                            <ng-container *ngSwitchCase="'mdi'">
                              <mat-icon
                                [ngClass]="suffix.form.icon.cssClass"
                                class="mdi-icon"
                                [matTooltip]="tooltipConditions(suffix?.form?.icon?.tooltip, suffix?.form)"
                                [matTooltipDisabled]="!suffix?.form?.icon?.tooltip"
                                [attr.size]="suffix?.form?.icon?.size ? suffix?.form?.icon?.size : '12px'"
                                svgIcon="{{ suffix?.form?.icon?.name }}"
                              ></mat-icon>
                            </ng-container>
                            <ng-container *ngSwitchCase="null">
                              <mat-icon
                                [matTooltip]="tooltipConditions(suffix?.form?.icon?.tooltip, suffix?.form)"
                                [matTooltipDisabled]="!suffix?.form?.icon?.tooltip"
                              >
                                {{ suffix?.form?.icon?.name }}
                              </mat-icon>
                            </ng-container>
                          </ng-container>
                        </ng-container>
                      </span>
                    </ng-container>
                    <ng-container *ngSwitchCase="null">
                      {{ suffix.form.translate ? (suffix.form.staticText | translate) : suffix.form.staticText }}
                    </ng-container>
                  </ng-container>
                </ng-container>
              </div>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="'checkbox_multi'">
            <app-multi-checkbox
              [form]="form"
              [field]="field"
              [cid]="service?.cid"
              [selectionsArray]="service.lists[field.form.listPrefix]"
            ></app-multi-checkbox>
          </ng-container>

          <ng-container *ngSwitchCase="'mat-slide-toggle'">
            <div
              class="form-input-size field-container mat-slide-toggle-input-container"
              [ngClass]="field?.form?.cssClass"
            >
              <label class="bold d-flex clearfix pt-2 pb-2">
                <span>
                  {{
                    field.form.apiName
                      ? field.form.name
                      : (cid + '.' + (field.form.name ? field.form.name : field.name) | translate)
                  }}
                </span>
                <span *ngIf="checkRequiredFields(field.name)" class="mat-error required-asterisk inline"> * </span>
              </label>
              <mat-slide-toggle
                color="primary"
                class="bold d-flex clearfix pt-2 pb-2"
                [formControlName]="field.name"
                [attr.testId]="field.name"
                [afaqyHeatmap]="{ page: service?.cid, target: 'form-input-' + field.name }"
              >
                <!-- {{ cid + '.' + field.name | translate }} -->
                <!-- {{
                  field.form.apiName
                    ? field.form.name
                    : (cid + '.' + (field.form.name ? field.form.name : field.name) | translate)
                }} -->
              </mat-slide-toggle>

              <div matSuffix *ngIf="field.form.suffix" class="display-inline-flex">
                <ng-container *ngFor="let suffix of field.form.suffix">
                  <ng-container [ngSwitch]="suffix.form.displayType">
                    <ng-container *ngSwitchCase="'icon'">
                      {{ suffix.form.translate ? (suffix.form.staticText | translate) : suffix.form.staticText }}
                      <span class="cell-display-type icon">
                        <ng-container *ngIf="suffix.form.icon">
                          <ng-container [ngSwitch]="suffix.form.icon.type">
                            <ng-container *ngSwitchCase="'mdi'">
                              <mat-icon
                                [ngClass]="suffix.form.icon.cssClass"
                                class="mdi-icon"
                                [matTooltip]="tooltipConditions(suffix?.form?.icon?.tooltip, suffix?.form)"
                                [matTooltipDisabled]="!suffix?.form?.icon?.tooltip"
                                [attr.size]="suffix?.form?.icon?.size ? suffix?.form?.icon?.size : '12px'"
                                svgIcon="{{ suffix?.form?.icon?.name }}"
                              ></mat-icon>
                            </ng-container>
                            <ng-container *ngSwitchCase="null">
                              <mat-icon
                                [matTooltip]="tooltipConditions(suffix?.form?.icon?.tooltip, suffix?.form)"
                                [matTooltipDisabled]="!suffix?.form?.icon?.tooltip"
                              >
                                {{ suffix?.form?.icon?.name }}
                              </mat-icon>
                            </ng-container>
                          </ng-container>
                        </ng-container>
                      </span>
                    </ng-container>
                    <ng-container *ngSwitchCase="null">
                      {{ suffix.form.translate ? (suffix.form.staticText | translate) : suffix.form.staticText }}
                    </ng-container>
                  </ng-container>
                </ng-container>
              </div>
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="'datepicker'">
            <div class="form-input-size field-container">
              <app-date-picker
                [requiredAsterisk]="checkRequiredFields(field.name)"
                [service]="service"
                [form]="form"
                [isEdit]="isEdit"
                [field]="field"
                [isClone]="isClone"
                [formControlName]="field?.name"
                [name]="field?.name"
                [disabledInput]="(form?.controls)[field.name].disabled"
                (valueChanged)="onChangeValue($event?.field, $event?.event)"
              >
              </app-date-picker>

              <!--              <date-picker-v2-component-->
              <!--                [requiredAsterisk]="checkRequiredFields(field.name)"-->
              <!--                [service]="service"-->
              <!--                [form]="form"-->
              <!--                [isEdit]="isEdit"-->
              <!--                [field]="field"-->
              <!--                [isClone]="isClone"-->
              <!--              >-->
              <!--              </date-picker-v2-component>-->
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="'timepicker'">
            <div class="form-input-size field-container">
              <app-time-picker
                [requiredAsterisk]="checkRequiredFields(field.name)"
                [service]="service"
                [form]="form"
                [isEdit]="isEdit"
                [field]="field"
                [isClone]="isClone"
                [formControlName]="field?.name"
                [name]="field?.name"
                [disabledInput]="(form?.controls)[field.name].disabled"
                (valueChanged)="onChangeValue($event?.field, $event?.event)"
              >
              </app-time-picker>
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="'dateTimeInput'">
            <div class="form-input-size field-container">
              <app-date-time
                *ngIf="form.controls[field.name]"
                [cid]="cid"
                [field]="field"
                [form]="form"
                [key]="field.name"
                [service]="service"
                [isEdit]="isEdit"
                [minEntryDate]=""
                [maxEntryDate]=""
              >
              </app-date-time>

              <mat-error>
                <ng-container *ngTemplateOutlet="validationErrors"></ng-container>
              </mat-error>
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="'dateTimeRangeInput'">
            <div class="form-input-size field-container">
              <app-date-time-range
                *ngIf="form.controls[field.name]"
                [cid]="cid"
                [field]="field"
                [form]="form"
                [key]="field.name"
                [service]="service"
                [isEdit]="isEdit"
              >
              </app-date-time-range>

              <mat-error>
                <ng-container *ngTemplateOutlet="validationErrors"></ng-container>
              </mat-error>
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="'select'">
            <div class="form-input-size field-container" [ngClass]="field?.form?.cssClass">
              <mat-form-field class="full-width">
                <mat-label class="bold d-flex clearfix pt-2 pb-2">
                  <span>{{ cid + '.' + (field.form.name ? field.form.name : field.name) | translate }}</span>
                  <span *ngIf="checkRequiredFields(field.name)" class="mat-error required-asterisk inline"> *</span>
                </mat-label>
                <mat-select
                  [formControlName]="field.name"
                  [attr.testId]="field.name"
                  [afaqyHeatmap]="{ page: service?.cid, target: 'form-input-' + field.name }"
                >
                  <mat-option *ngFor="let item of service.lists[field.form.listPrefix]" [value]="item.id">
                    {{ item.name || item.displayName | translate }}
                  </mat-option>
                </mat-select>

                <mat-error>
                  <ng-container *ngTemplateOutlet="validationErrors"></ng-container>
                </mat-error>
              </mat-form-field>
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="'radio'">
            <div class="form-input-size field-container radio-group">
              <label class="bold d-flex clearfix pt-2 pb-2">
                <span style="font-size: 14px; margin-bottom: 15px">{{
                  cid + '.' + (field.form.name ? field.form.name : field.name) | translate
                }}</span>
                <span *ngIf="checkRequiredFields(field.name)" class="mat-error required-asterisk inline"> *</span>
              </label>

              <mat-radio-group
                [ngClass]="field?.form?.cssClass"
                [formControlName]="field.name"
                aria-label="Select an option"
                [attr.testId]="field.name"
                [afaqyHeatmap]="{ page: service?.cid, target: 'form-input-' + field.name }"
              >
                <mat-radio-button
                  color="primary"
                  *ngFor="let item of service.lists[field.form.listPrefix]"
                  [value]="item.id"
                  [afaqyHeatmap]="{ page: service?.cid, target: 'form-input-' + item.name }"
                >
                  {{ item.name || item.displayName | translate }}
                </mat-radio-button>
              </mat-radio-group>
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="'radio_translated'">
            <div class="form-input-size field-container radio-group">
              <label class="bold d-flex clearfix pt-2 pb-2">
                <span>{{ cid + '.' + (field.form.name ? field.form.name : field.name) | translate }}</span>
                <span *ngIf="checkRequiredFields(field.name)" class="mat-error required-asterisk inline"> *</span>
              </label>

              <mat-radio-group
                [ngClass]="field?.form?.cssClass"
                [formControlName]="field.name"
                aria-label="Select an option"
                [attr.testId]="field.name"
                [afaqyHeatmap]="{ page: service?.cid, target: 'form-input-' + field.name }"
              >
                <mat-radio-button
                  color="primary"
                  *ngFor="let item of service.lists[field.form.listPrefix]"
                  [value]="item.id"
                  [afaqyHeatmap]="{ page: service?.cid, target: 'form-input-' + item.name }"
                >
                  {{ item.name || item.displayName | translate }}
                </mat-radio-button>
              </mat-radio-group>
            </div>

            <mat-error>
              <ng-container *ngTemplateOutlet="validationErrors"></ng-container>
            </mat-error>
          </ng-container>

          <ng-container *ngSwitchCase="'button-toggle'">
            <div class="form-input-size field-container radio-group">
              <label class="bold d-flex clearfix pt-2 pb-2">
                <span>{{ cid + '.' + (field.form.name ? field.form.name : field.name) | translate }}</span>
                <span *ngIf="checkRequiredFields(field.name)" class="mat-error required-asterisk inline"> *</span>
              </label>

              <mat-button-toggle-group
                class="mat-button-toggle-group"
                name="fontStyle"
                aria-label="Font Style"
                [ngClass]="field?.form?.cssClass"
                [formControlName]="field.name"
                aria-label="Select an option"
                [attr.testId]="field.name"
                [afaqyHeatmap]="{ page: service?.cid, target: 'form-input-' + field.name }"
              >
                <mat-button-toggle
                  [disableRipple]="true"
                  color="primary"
                  *ngFor="let item of service.lists[field.form.listPrefix]"
                  [value]="item.id"
                  [afaqyHeatmap]="{ page: service?.cid, target: 'form-input-' + item.name }"
                >
                  {{ item.name || item.displayName | translate }}
                </mat-button-toggle>
              </mat-button-toggle-group>
            </div>

            <mat-error>
              <ng-container *ngTemplateOutlet="validationErrors"></ng-container>
            </mat-error>
          </ng-container>

          <!--        <ng-container *ngSwitchCase="'color_picker'">-->
          <!--          <div-->
          <!--            style="width: 400px;  display: flex;  flex-direction: column;  align-items: center; justify-content: center;"-->
          <!--          >-->
          <!--            <input-->
          <!--              style="height: 60px;  width: 60px;  border-radius: 50%; margin: 5px 0; border: none;  text-align: center;"-->
          <!--              [matTooltip]="'common.click_to_change' | translate"-->
          <!--              [formControlName]="field.name"-->
          <!--              [colorPicker]="form.controls[field.name].value"-->
          <!--              (colorPickerChange)="setColor(field.name, $event)"-->
          <!--              [style.background]="form.controls[field.name].value || '#e3e3e3'"-->
          <!--            />-->
          <!--            <label>{{ cid + '.' + field.name | translate }}</label>-->
          <!--          </div>-->

          <!--          <mat-error>-->
          <!--            <ng-container *ngTemplateOutlet="validationErrors"></ng-container>-->
          <!--          </mat-error>-->

          <!--        </ng-container>-->

          <ng-container *ngSwitchCase="'file_input'">
            <div class="form-input-size field-container">
              <div style="display: flex" *ngIf="form.controls[field.name]?.value">
                <img
                  width="300"
                  [src]="form.controls[field.name]?.valueChanges ? previewImage : form.controls[field.name]?.value"
                  [alt]="field?.name"
                />
              </div>
              <div style="width: 400px; display: flex; flex-direction: column">
                <label
                  style="
                    width: 100%;
                    border-radius: 10px;
                    border: 1px dashed #2a3348;
                    margin: 10px 0;
                    padding: 10px;
                    text-align: center;
                  "
                  for="file"
                  >{{ cid + '.' + field?.name | translate }}</label
                >
                <input
                  style="width: 0.1px; height: 0.1px"
                  id="file"
                  type="file"
                  [formControlName]="field.name"
                  (change)="uploadPhoto($event, field)"
                  [attr.testId]="field.name"
                  [placeholder]="cid + '.' + (field.form.placeHolder ? field.form.placeHolder : field.name) | translate"
                  [afaqyHeatmap]="{ page: service?.cid, target: 'form-input-' + field.name }"
                />
              </div>

              <mat-error>
                <ng-container *ngTemplateOutlet="validationErrors"></ng-container>
              </mat-error>
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="'img'">
            <div
              class="fileUpload image-upload field-container"
              [ngClass]="{ disabled: form.get(field?.name)?.disabled }"
            >
              <div class="image-container">
                <div class="buttons-container">
                  <button
                    mat-mini-fab
                    *ngIf="form.controls[field.name]?.value !== null"
                    class="delete"
                    (click)="clearImage(form.controls[field.name])"
                    [afaqyHeatmap]="{ page: service?.cid, target: 'form-input-clear-' + field.name }"
                  >
                    <mat-icon color="primary" class="mdi-icon" svgIcon="delete-outline" [attr.size]="'14px'"></mat-icon>
                  </button>

                  <!--                  *ngIf="form.controls[field.name]?.disabled && isEdit && field?.form?.editDisabled"-->
                  <button
                    mat-mini-fab
                    class="edit"
                    (click)="openFileSelectFromInput(form.controls[field.name], field.name)"
                    [afaqyHeatmap]="{ page: service?.cid, target: 'form-input-open-file-select-' + field.name }"
                  >
                    <mat-icon color="warn" class="mdi-icon" svgIcon="pencil-outline" [attr.size]="'14px'"></mat-icon>
                  </button>
                </div>
                <img class="img-fluid" [src]="checkImgSrc(field)" [alt]="field?.name" />
                <span class="img-label" *ngIf="!form.controls[field.name]?.value">{{
                  field?.form?.placeHolderText
                    ? (field?.form?.placeHolderText | translate)
                    : ('common.upload_photo' | translate)
                }}</span>
              </div>
              <div class="input-container">
                <label *ngIf="!form.controls[field.name]?.disabled" [@showHide] [for]="field.name">
                  <!--                              {{ cid + '.' + field?.name | translate }}-->
                </label>
                <input
                  [disabled]="form.controls[field.name]?.disabled"
                  class="upload-file-hidden"
                  [id]="field.name"
                  type="file"
                  accept=".png, .jpg, .jpeg"
                  (change)="uploadPhoto($event, field, form.controls[field.name].value)"
                  [attr.testId]="field.name"
                  [placeholder]="cid + '.' + (field.form.placeHolder ? field.form.placeHolder : field.name) | translate"
                />
                <input type="hidden" [formControlName]="field.name" />
              </div>
              <!--                        <button-->
              <!--                          mat-raised-button-->
              <!--                          color="primary"-->
              <!--                          *ngIf="form.controls[field.name]?.enabled && isEdit && field?.form?.editDisabled"-->
              <!--                          class="edit"-->
              <!--                          (click)="uploadNewPhoto(form.controls[field.name].value , field)"-->
              <!--                        >-->
              <!--                          {{ 'common.upload' | translate }}-->
              <!--                        </button>-->
            </div>
          </ng-container>

          <!-- img cropper -->

          <ng-container *ngSwitchCase="'img_cropper'">
            <div
              class="fileUpload image-upload field-container"
              [ngClass]="{ disabled: form.get(field?.name)?.disabled }"
            >
              <div class="image-container">
                <div class="buttons-container">
                  <button
                    mat-mini-fab
                    *ngIf="form.controls[field.name]?.value !== null"
                    class="delete"
                    (click)="clearImage(form.controls[field.name])"
                  >
                    <mat-icon color="primary" class="mdi-icon" svgIcon="delete-outline" [attr.size]="'14px'"></mat-icon>
                  </button>

                  <!--                  *ngIf="form.controls[field.name]?.disabled && isEdit && field?.form?.editDisabled"-->
                  <button
                    mat-mini-fab
                    class="edit"
                    (click)="openFileSelectFromInput(form.controls[field.name], field.name)"
                  >
                    <mat-icon color="warn" class="mdi-icon" svgIcon="pencil-outline" [attr.size]="'14px'"></mat-icon>
                  </button>
                </div>
                <img class="img-fluid" [src]="checkCroppedImgSrc(field)" [alt]="field?.name" />
                <span class="img-label" *ngIf="!form.controls[field.name]?.value">{{
                  field?.form?.placeHolderText
                    ? (field?.form?.placeHolderText | translate)
                    : ('common.upload_photo' | translate)
                }}</span>
              </div>
              <div class="input-container">
                <label *ngIf="!form.controls[field.name]?.disabled" [@showHide] [for]="field.name">
                  <!--                              {{ cid + '.' + field?.name | translate }}-->
                </label>
                <input
                  #fileInput
                  [disabled]="form.controls[field.name]?.disabled"
                  class="upload-file-hidden"
                  [id]="field.name"
                  type="file"
                  accept=".png, .jpg, .jpeg"
                  (change)="uploadPhotoAndCrop($event, field, form.controls[field.name].value)"
                  [attr.testId]="field.name"
                  (click)="fileInput.value = null"
                />
                <input type="hidden" [formControlName]="field.name" />
              </div>
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="'permissions'">
            <app-permissions
              [service]="service"
              [item]="form?.get(field?.name)?.value"
              [form]="form"
              [isEdit]="isEdit"
              [isClone]="isClone"
              (UpdateFormFieldValue)="UpdateFormFieldValue($event, field)"
            ></app-permissions>
            <input type="hidden" [formControlName]="field?.name" />
          </ng-container>

          <ng-container *ngSwitchCase="'file_upload'">
            <div class="field-container input-container">
              <label *ngIf="!form.controls[field.name]?.disabled" [@showHide] [for]="field.name">
                <!--              {{ cid + '.' + field?.name | translate }}-->
              </label>
              <input
                class="input-file"
                type="file"
                accept="*/*"
                [id]="field.name"
                (change)="uploadFile($event, field)"
                [attr.testId]="field.name"
                [placeholder]="cid + '.' + (field.form.placeHolder ? field.form.placeHolder : field.name) | translate"
              />
              <input
                type="hidden"
                [formControlName]="field.name"
                [afaqyHeatmap]="{ page: service?.cid, target: 'form-input-' + field.name }"
              />
              <div style="display: inline-block; position: absolute">
                <mat-icon class="mdi-icon" svgIcon="file-document-box-plus-outline" [attr.size]="'20px'"></mat-icon>
              </div>
              <div style="display: inline-block; margin-left: 35px; margin-top: -2px">
                <span style="font-size: 12px; color: #97a5cb">{{ 'documents.click' | translate }}</span>
              </div>
              <div>
                <button mat-button class="browse">{{ 'documents.browse' | translate }}</button>
              </div>

              <mat-error>
                <ng-container *ngTemplateOutlet="validationErrors"></ng-container>
              </mat-error>
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="'file_download_details'">
            <div class="field-container file_download_details" *ngIf="(form?.controls)[field?.name]?.value">
              <div fxFlex="" fxLayout="row" class="d-flex align-items-center justify-content-center">
                <a
                  class="d-flex align-items-center justify-content-center"
                  mat-button
                  [href]="(form?.controls)[field?.name]?.value"
                  target="_blank"
                >
                  <mat-icon
                    matPrefix
                    svgIcon="file-download-outline"
                    class="mdi-icon d-inline-flex align-items-center justify-content-center"
                    [attr.size]="'14px'"
                  >
                  </mat-icon>
                  <span class="primary-label">
                    {{ 'common.download_file' | translate }}
                  </span>
                </a>
              </div>
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="'file_upload_details'">
            <div class="field-container file_upload_details" [ngClass]="{ disabled: form.get(field?.name)?.disabled }">
              <div
                fxFlex=""
                fxLayout="row"
                class="input-container d-flex align-items-center justify-content-flex-start position-relative"
              >
                <div class="icon">
                  <mat-icon svgIcon="file-document-box-plus-outline" [attr.size]="'14px'"></mat-icon>
                </div>
                <div fxLayout="column" class="labels" *ngIf="!selectedFiles[field?.name]">
                  <span class="primary-label">
                    {{ 'common.drop_files' | translate }}
                  </span>
                  <span class="secondary-label">
                    {{ 'common.pick_files' | translate }}
                  </span>
                </div>

                <div fxLayout="column" class="labels" *ngIf="selectedFiles[field?.name]">
                  <span class="primary-label">
                    {{ selectedFiles[field?.name]?.name }}
                  </span>
                  <span class="secondary-label">
                    {{ 'common.size' | translate }}
                    {{ selectedFiles[field?.name]?.size }} Kb
                  </span>
                </div>

                <div class="action-button">
                  <button mat-flat-button color="primary" class="upload_button">
                    {{ 'common.browse' | translate }}
                  </button>
                </div>
                <input
                  [disabled]="(form?.controls)[field?.name]?.disabled"
                  class="upload-file-hidden"
                  [id]="field.name"
                  type="file"
                  accept="*/*"
                  (change)="uploadFile($event, field)"
                  [placeholder]="cid + '.' + (field.form.placeHolder ? field.form.placeHolder : field.name) | translate"
                />
                <div
                  *ngIf="isEdit && (form?.controls)[field?.name]?.disabled"
                  class="upload-file-hidden edit"
                  (click)="openFileSelectFromInput(form.controls[field.name], field.name)"
                ></div>
                <input type="hidden" [formControlName]="field?.name" />
              </div>
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="'img_upload_details'">
            <app-img-upload-details
              [field]="field"
              [form]="form"
              [service]="service"
              [isEdit]="isEdit"
            ></app-img-upload-details>
          </ng-container>

          <ng-container *ngSwitchCase="'date_range'">
            <div *ngIf="field?.list?.filterOptions">
              <p class="date-filter-field-label">{{ service.cid + '.' + field?.name | translate }}</p>
              <app-date-range
                [group]="form"
                [key]="field?.name"
                [appliedFilter]="appliedFilter"
                [minEntryDate]="
                  service?.resourcesList?.additionalData && field?.list?.filterOptions?.minRange?.prop
                    ? service?.getValue(
                        service?.resourcesList?.additionalData,
                        field?.list?.filterOptions?.minRange?.prop
                      )
                    : field?.list?.filterOptions?.minRange?.initial
                    ? field?.list?.filterOptions?.minRange?.initial
                    : service?.shared?.moment('today')
                "
                [maxEntryDate]="
                  service?.resourcesList?.additionalData && field?.list?.filterOptions?.maxRange?.prop
                    ? service?.getValue(
                        service?.resourcesList?.additionalData,
                        field?.list?.filterOptions?.maxRange?.prop
                      )
                    : field?.list?.filterOptions?.maxRange?.initial
                    ? field?.list?.filterOptions?.maxRange?.initial
                    : service?.shared?.moment('today')
                "
                [momentObj]="field?.list?.filterOptions?.momentObj"
              >
              </app-date-range>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="'range_slider'">
            <div *ngIf="field?.list?.filterOptions && service?.resourcesList?.additionalData">
              <p>{{ service.cid + '.' + field?.name | translate }}</p>
              <app-slider-range
                [floor]="
                  service?.getValue(service?.resourcesList?.additionalData, field?.list?.filterOptions?.minRange?.prop)
                "
                [ceil]="
                  service?.getValue(service?.resourcesList?.additionalData, field?.list?.filterOptions?.maxRange?.prop)
                "
                [appliedFilter]="appliedFilter"
                [step]="field?.list?.filterOptions?.stepRange"
                [prefixUnit]="'common.unit_types.' + (field?.list?.filterOptions?.prefixUnit | lowercase) | translate"
                [form]="form"
                [key]="field?.name"
                [manualRefresh]="manualRefresh"
              >
              </app-slider-range>
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="'phone'">
            <div class="form-input-size field-container" [ngClass]="field?.form?.cssClass">
              <mat-label class="bold d-flex clearfix pt-2 pb-2">
                {{ cid + '.' + (field.form.name ? field.form.name : field.name) | translate }}
                <span *ngIf="checkRequiredFields(field.name)" class="mat-error required-asterisk inline"> * </span>
              </mat-label>
              <mat-form-field class="full-width" appearance="outline">
                <ngx-mat-intl-tel-input
                  [preferredCountries]="['sa']"
                  [enablePlaceholder]="true"
                  [enableSearch]="true"
                  [formControlName]="field.name"
                  [inputPlaceholder]="
                    cid + '.' + (field.form.placeHolder ? field.form.placeHolder : field.name) | translate
                  "
                  [afaqyHeatmap]="{ page: service?.cid, target: 'form-input-' + field.name }"
                >
                </ngx-mat-intl-tel-input>
                <mat-error>
                  <ng-container *ngTemplateOutlet="validationErrors"></ng-container>
                </mat-error>
              </mat-form-field>
            </div>
          </ng-container>

          <!--Location picker Done-->
          <ng-container *ngSwitchCase="'locationPicker'">
            <mat-label>
              {{ cid + '.' + (field.form.name ? field.form.name : field.name) | translate }}
              <span *ngIf="checkRequiredFields(field.name)" class="mat-error required-asterisk inline"> * </span>
            </mat-label>
            <app-leaflet-map
              *ngIf="form.controls[field.name]"
              [cid]="cid"
              [field]="field"
              [form]="form"
              [key]="field.name"
              [service]="service"
              [isEdit]="isEdit"
              [inputDisabled]="form.controls[field.name]?.disabled"
            >
            </app-leaflet-map>
          </ng-container>

          <!--Location Draw Done-->
          <ng-container *ngSwitchCase="'leafletDraw'">
            <mat-label>
              {{ cid + '.' + (field.form.name ? field.form.name : field.name) | translate }}
              <span *ngIf="checkRequiredFields(field.name)" class="mat-error required-asterisk inline"> * </span>
            </mat-label>
            <app-leaflet-draw
              *ngIf="form.controls[field.name]"
              [cid]="cid"
              [field]="field"
              [form]="form"
              [key]="field.name"
              [service]="service"
              [isEdit]="isEdit"
              [inputDisabled]="form.controls[field.name]?.disabled"
            >
            </app-leaflet-draw>
          </ng-container>

          <!--  validation-errors  -->
          <ng-template #validationErrors>
            <ng-container *ngIf="form?.get(field?.name)?.errors">
              <!--        <ng-container >-->
              <div
                class="validation-errors"
                *ngIf="
                  (form?.get(field?.name)?.touched || isSubmitted) &&
                  form?.get(field?.name)?.invalid &&
                  form?.get(field?.name)?.errors
                "
              >
                <div class="mat-error" *ngFor="let error of form?.get(field?.name)?.errors | keyvalue">
                  <!-- Server Side Errors -->
                  <ng-container *ngIf="error?.key === 'serverError'">
                    <ng-container *ngFor="let err of error?.value">
                      {{ err }}
                    </ng-container>
                  </ng-container>

                  <!-- Client Side Errors-->
                  <ng-container *ngIf="error?.key !== 'serverError'">
                    <ng-container *ngIf="!field?.hints?.password_helper">
                      <ng-container [ngSwitch]="error?.key">
                        <ng-container *ngSwitchCase="'minlength'">
                          {{ 'validation_errors.' + error?.key | translate : { xx: error?.value?.requiredLength } }}
                        </ng-container>
                        <ng-container *ngSwitchCase="'maxlength'">
                          {{ 'validation_errors.' + error?.key | translate : { xx: error?.value?.requiredLength } }}
                        </ng-container>
                        <ng-container *ngSwitchDefault>
                          {{ 'validation_errors.' + error?.key | translate }}
                        </ng-container>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                </div>
              </div>
            </ng-container>
          </ng-template>
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>
